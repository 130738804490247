<template>
  <div class="home-wrapper">
    <div class="video-wrapper">
      <video
        ref="videoPlayer"
        class="video-js video-player vjs-default-skin vjs-big-play-centered"
        controls
        data-setup='{"aspectRatio":"16:9"}'
        playsinline
        webkit-playsinline
      ></video>
    </div>
  </div>
</template>

<script>
import 'video.js/dist/video-js.css';
import videojs from 'video.js';

const loadTime = Date.now();
console.log('CloutHub Player 1.3');

function cdn(src) {
  return src.replace(
    'ev-uploads-prod.s3.amazonaws.com',
    'upload.efficientvideo.com'
  );
}

export default {
  name: 'Home',
  data() {
    return {
      broadcastChannel: new BroadcastChannel('ch-video-player'),
      playerId: `${Math.random()}`,
      autoplayTimeout: undefined,
      src: cdn(this.$route.query.src),
      poster: this.$route.query.poster,
    };
  },
  computed: {
    contentType() {
      return this.src.includes('.m3u8') ? 'application/x-mpegURL' : 'video/mp4';
    },
  },
  unmounted() {
    this.broadcastChannel.removeEventListener('message', this.onSomePlayerPlay);
    this.broadcastChannel.close();
  },
  async mounted() {
    this.play(!!this.$route.query.autoplay, !!this.$route.query.muted);
    window.addEventListener('message', (evt) => {
      if (evt && evt.data && evt.data.type === 'pause-video') {
        if (this.player) {
          this.player.pause();
        }
      }
    });
    this.broadcastChannel.addEventListener('message', this.onSomePlayerPlay);
  },
  methods: {
    pause() {
      if (this.player) {
        this.player.pause();
      }
    },
    notifyPlay() {
      this.broadcastChannel.postMessage({
        type: 'ch-video-play',
        playerId: this.playerId,
      });
    },
    onSomePlayerPlay({ data }) {
      if (data.type === 'ch-video-play' && data.playerId !== this.playerId) {
        clearTimeout(this.autoplayTimeout);
        this.pause();
      }
    },
    async play(autoplay, isMuted = false) {
      await this.$nextTick();
      const src = this.src;
      const type = this.contentType;
      const options = {
        fluid: true,
        responsive: true,
        poster: this.poster,
        controls: true,
        muted: isMuted,
        sources: [
          {
            src,
            type,
          },
        ],
        html5: {
          vhs: {
            overrideNative: true
          },
          nativeAudioTracks: false,
          nativeVideoTracks: false,
        },
      };
      this.player = videojs(this.$refs.videoPlayer, options, async () => {
        if (autoplay) {
          this.autoplayTimeout = setTimeout(() => {
            this.player.volume(0);
            this.player.muted(true);
            this.player.play();
          }, 0);
        }
      });
      window.player = this.player;
      this.player.on('play', this.notifyPlay);
      try {
        if (window.evanalytics && window.evanalytics.monitor) {
          evanalytics.monitor(this.player, videojs);
        }
      } catch (err) {
        console.log('Analytics error', err);
      }
    },
  },
};
</script>
<style scoped>
/* hide the captions settings item from the captions menu */
/* .vjs-texttrack-settings {
  display: none;
} */

.home-wrapper {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  background-color: black;
}
.video-wrapper {
  flex: 1;
  flex-direction: column;
}
.video-player {
  flex: 1;
}
.video-js {
  width: 100% !important;
  height: 100% !important;
}
.transcoding-warning {
  color: white;
}
.video-js :deep(.vjs-text-track-cue) {
  font-size: 20px !important;
}
</style>
